<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import Icon from "@iconify/svelte";
  import { menuItems, menuVisible, toggleMenu } from "./menu-store";
  import { arrowUp } from "../assets/icons";

  const appearUp = { duration: 300, y: 50 };
  const appearRight = { duration: 300, x: 100 };
  let backToTopVisible = false;
  let innerWidth = window.innerWidth;

  $: appear = innerWidth < 60 * 16 ? appearUp : appearRight;

  const scrollToTop = () => {
    document.querySelector("body")?.scrollIntoView({ behavior: "smooth" });
  };

  const handleScroll = () => {
    const scrolled = document.querySelector("html")?.scrollTop || 0;
    backToTopVisible = scrolled > window.innerHeight;
  };

  onMount(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
</script>

<!--
  @component
  ### menu.svelte

  Component that displays a menu with links
  
  #### Props
  pathname - Current path
-->
<svelte:window bind:innerWidth />
<div class="NavWrapper">
  {#if $menuVisible}
    <nav transition:fly={appear} class="Nav" data-testid="menu">
      {#each $menuItems as { path, label }}
        <a href={path} on:click={toggleMenu} class="Link">
          {label}
        </a>
      {/each}
    </nav>
  {/if}
  {#if $menuVisible}
    <button
      type="button"
      class="Toggle"
      on:click={toggleMenu}
      data-testid="hide-menu">Hide menu</button
    >
  {:else}
    <button
      type="button"
      class="Toggle"
      on:click={toggleMenu}
      data-testid="show-menu">Show menu</button
    >
  {/if}
</div>

{#if backToTopVisible}
  <button type="button" class="BackToTop" on:click={scrollToTop}>
    <Icon width={36} height={36} icon={arrowUp} />
    <span class="sr-only">Back to top</span>
  </button>
{/if}

<style>
  .NavWrapper,
  .BackToTop {
    position: fixed;
    z-index: var(--index-top);
  }

  .NavWrapper,
  .Nav {
    display: flex;
    flex-flow: column;
    gap: 1rem;

    @media screen and (min-width: 60rem) {
      flex-flow: row;
    }
  }

  .NavWrapper {
    bottom: 2rem;
    right: 2rem;
  }

  .BackToTop {
    left: 1rem;
    top: 1rem;
    display: inline-flex;
    padding: 0.5rem;
    border-radius: 100%;
    border: none;
    background: var(--black);
    color: var(--white);

    &:hover {
      background: var(--primary);
    }
  }

  .Toggle,
  .Link {
    font-size: inherit;
    font-family: var(--font-alt);
    padding: 0.5rem 1rem;
    border-radius: var(--radius-lg);
  }

  .Toggle {
    font-weight: bold;
    background: var(--white);
    color: var(--black);
    border: 1px solid var(--black);
    position: relative;
  }

  .Link {
    text-decoration: none;
    text-align: center;
    background: var(--white);
  }

  .Link:hover {
    background: var(--primary);
    color: var(--white);
    transition: all 0.2s var(--ease);
  }
</style>
